body {
  margin: 0 !important;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Ensure no horizontal scrolling */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  background-color: "#282828";
  padding-top: env(safe-area-inset-top);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Make sure nothing overflows horizontally */
}

@supports (padding-top: env(safe-area-inset-top)) {
  body {
    background-color: "#282828"; /* Make the color match your background */
  }
}
