.swiper-button-next,
.swiper-button-prev {
  color: #000; /* Change arrow color to black */
  background-color: #f5f7fa; /* Set background color to light gray */
  width: 32px;
  height: 32px;
  border-radius: 10px; /* Keep slightly rounded corners */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add a slight shadow for depth */
  margin: 1px;
  padding-bottom: "0px";
}

/* Move the arrows outside the Swiper container */
.swiper-button-next {
  right: -1px; /* Move it 25px outside the Swiper container */
}

.swiper-button-prev {
  left: -1px; /* Move it 25px outside the Swiper container */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px;
  color: #000; /* Change arrow color */
}

@media all and (min-width: 768px) {
  /* CSS rules here for screens above 750px */
}

@media all and (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    visibility: hidden;
  }
}

.swiper-pagination-bullet {
  background-color: #3e4347; /* Default color */
  opacity: 1; /* Make sure bullets are fully opaque */
}

.swiper-pagination-bullet-active {
  background-color: #ebc47f; /* Active bullet color */
}

.swiper-pagination-bullet {
  transition: background-color 0.3s ease; /* Smooth transition */
}
